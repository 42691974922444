import React from 'react';
import { Card, Space, Button, Input, Alert } from 'antd';
import './index.css';
import { useState } from 'react';


const MyCard = () => {

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
    }

    const [userName, setUserName] = useState('');
    const [studentName, setStudentName] = useState('');
    const [email, setEmail] = useState();

    const [state, setState] = useState(0);
    //0 = null
    //1 = submitted
    //2 = invalid email
    //3 = empty fields

    function validateEmail(testEmail) {
        var re = /\S+@\S+\.\S+/;
        return re.test(testEmail);
    }

    function handleSubmit() {
        if (validateEmail(email)) {
            if (studentName.length > 1 && userName.length > 1) {

                const msgOut = {
                    username: userName,
                    studentname: studentName,
                    email: email,
                    time: timeConverter(Date.now()/1000)
                }

                fetch('/msg', {mode: 'cors', method: 'POST', body: JSON.stringify(msgOut), headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
                    .then(async response => {
                        if (!response.ok) {
                            setState(4);
                        } else {
                            setState(1);
                        }
                    })
            } else {
                setState(3);
            }
        } else {
            setState(2);
        }
    }

    function SubmitButton() {
        if (state === 0) {
            return (
                <Button type="dashed" style={{background: "#d1cfe2"}} onClick={() => {handleSubmit()}}>Submit</Button>
            )
        } else if (state === 2) {
            return (
                <>
                    <Alert message="Invalid email address" type="error"/>
                    <Button type="dashed" style={{background: "#d1cfe2"}} onClick={() => {handleSubmit()}}>Submit</Button>
                </>
            )
        } else if (state === 3) {
            return (
                <>
                    <Alert message="All fields must be filled" type="error"/>
                    <Button type="dashed" style={{background: "#d1cfe2"}} onClick={() => {handleSubmit()}}>Submit</Button>
                </>
            )
        } else if (state === 4) {
            return (
                <>
                    <Alert message="Error, try again" type="error"/>
                    <Button type="dashed" style={{background: "#d1cfe2"}} onClick={() => {handleSubmit()}}>Submit</Button>
                </>
            )
        } else {
            return (
                <Button type="dashed" disabled style={{background: "#d1cfe2"}}>Thank you!</Button>
            )
        }
    }

    return(
        <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '15%'}}>
            <div style={{position: 'absolute', top: '10%'}}>
                <Space direction="vertical" size={16}>
                    <Card
                    title="tutormillis.us"
                    style={{backgroundColor: '#daeaf6', width: '80%', textAlign: 'center'}}
                    >
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: 20}}>
                            Need to brush up on classes from this past year? Want to maintain knowledge over the summer? Just looking to learn something new?
                        </div>
                    </b>
                    <p style={{ display: 'flex', textAlign: 'center'}}>
                        We are experienced high school students looking to help out locally! We will tutor K-12 in any subject. Combined, we have taken 8 different AP exams and have extensive knowledge in any school-taught subject. We have tutoring experience, including in Spanish, and would love to work with anyone!
                    </p>
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: 20}}>
                            All tutoring for only:
                        </div>
                    </b>
                    <br/>
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: '200%'}}>
                            $25/hr/student 
                        </div>
                    </b>
                    <br/>
                    <p style={{ display: 'inline-flex', textAlign: 'center'}}>
                        If you can find tutoring anywhere locally for cheaper, let us know! We will beat any price!
                    </p>
                    
                    <br/>
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: 20}}>
                            Get in touch!
                        </div>
                    </b>
                    <br/>
                    <p style={{ display: 'inline-flex', textAlign: 'center'}}>
                        We will get back to you ASAP to schedule tutoring!
                    </p>
                    <br/>
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <Input placeholder='Your Name' onChange={(e) => setUserName(e.target.value)}></Input>
                        <Input placeholder='Student Name' onChange={(e) => setStudentName(e.target.value)}></Input>
                        <Input placeholder='Your Email' onChange={(e) => setEmail(e.target.value)}></Input>
                    </div>
                    <br/>
                    <SubmitButton/>
                    <br/>
                    <br/>
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: 20}}>
                            About us
                        </div>
                    </b>
                    <br/>
                    
                    <br/>
                    <img src={'http://tutormillis.us/images/IMG_3078-modified.png'} width={'40%'} length={'40%'}></img>
                    <br/>
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: 18}}>
                            Ryan Berube
                        </div>
                    </b>
                    <br/>
                    <p style={{ display: 'inline-flex', textAlign: 'center'}}>
                        4.49 weighted GPA, Millis Varsity Soccer Captain, and National Honor Society President. Going to Rensselaer Polytechnic Institute in Troy, NY, to purse Computer and Systems Engineering. Graduating Millis High School in June.
                    </p>
                    <br/>

                    <br/>
                    <img src={'http://tutormillis.us/images/IMG_0150-modified.png'} width={'40%'} length={'40%'}></img>
                    <br/>
                    <b style={{ display: 'inline-flex', textAlign: 'center'}}>
                        <div style={{fontSize: 18}}>
                            Lucy Sisto
                        </div>
                    </b>
                    <br/>
                    <p style={{ display: 'inline-flex', textAlign: 'center'}}>
                        4.76 weighted GPA, Class of 2024 President, and National Honor Society Secretary. Going to University of Massachusetts Amherst to study Biology, pursuing medical school. Graduating Millis High School in June.
                    </p>
                    <br/>
                    </Card>
                </Space>
            </div>
        </div>
    );
};

export default MyCard;